import { combineReducers } from 'redux';
import CustomAddress from '../components/Address/Reducer';
import WhatsAppSendMessageModalReducer from '../components/SendWhatsAppBusinessModal/Reducer';
import Analytics from '../containers/Analytics/Reducer';
import ApiAccessPolicy from '../containers/ApiAccessPolicy/Reducer';
import {
  Authentication,
  ForgotPassword,
  SetPassword,
  Signin,
  SignOut,
} from '../containers/Authentication/Reducers';
import CareerPage from '../containers/CareerPage/Reducer';
import CompanyEmployee from '../containers/CompanyEmployee/Reducer';
import { ConfirmIndicationReducer as ConfirmIndication } from '../containers/ConfirmIndication';
import CurriculumDownload from '../containers/CurriculumDownload/Reducer';
import DiversityLegalTerms from '../containers/DiversityLegalTerms/Reducer';
import EmailDomains from '../containers/EmailDomains/Reducer';
import { EmailTemplateReducer as EmailTemplate } from '../containers/EmailTemplate';
import { HomeReducer as Home } from '../containers/Home';
import GupyBadgeFeedbackInsight from '../containers/Home/components/GupyBadgeFeedbackInsights/GupyBadgeFeedbackInsightReducer';
import IndicatorsHomeInsights from '../containers/Home/components/IndicatorsHomeInsights/Reducer';
import JobWeekInsights from '../containers/Home/components/JobWeekInsights/Reducer';
import NewHomeJobWeekInsights from '../containers/Home/components/NewHomeContent/components/JobWeekInsights/Reducer';
import { IntegrationsReducer as Integrations } from '../containers/Integrations';
import { InterviewEventReducer as InterviewEvents } from '../containers/InterviewEvents';
import { InterviewScriptReducer as InterviewScriptV2 } from '../containers/InterviewScripts';
import { CandidateInsertionReducer } from '../containers/Job/JobApplication/components/CandidateInsertion';
import CandidateTests from '../containers/Job/JobApplication/components/CurriculumDataTabTests/CandidateTestsReducer';
import HiringCelebration from '../containers/Job/JobApplication/components/HiringCelebrationModal/HiringCelebrationReducer';
import { HiringInformationReducer } from '../containers/Job/JobApplication/components/HiringInformationModal';
import InterviewEvent from '../containers/Job/JobApplication/components/InterviewEvent/InterviewEventReducer';
import JobApplicationHistory from '../containers/Job/JobApplication/components/JobApplicationHistory/JobApplicationHistoryReducer';
import OnboardingGaia from '../containers/Job/JobApplication/components/OnboardingGaia/OnboardingGaiaReducer';
import RelocateCandidate from '../containers/Job/JobApplication/components/RelocateCandidate/RelocateCandidateReducer';
import SkillsEvaluationLegacy from '../containers/Job/JobApplication/components/SkillsEvaluationLegacy/SkillEvaluationLegacyReducer';
import Timeline from '../containers/Job/JobApplication/components/Timeline/TimelineReducer';
import JobCancel from '../containers/Job/JobCancel/Reducer';
import { JobClosureReducer } from '../containers/Job/JobClosure';
import { JobDeletionReducer } from '../containers/Job/JobDeletion';
import { JobOverviewReducer as JobOverview } from '../containers/Job/JobOverview';
import JobRegistrationForm from '../containers/Job/JobStep/JobRegistrationForm/store/JobRegistrationFormReducer';
import JobStepRegister from '../containers/Job/JobStep/JobStepRegisterSetup/store/JobStepRegisterReducer';
import CustomForms from '../containers/Job/JobStep/Reducer';
import JobSummary from '../containers/Job/JobSummary/Reducer';
import JobCustomFormTemplate from '../containers/JobCustomFormTemplate/Reducer';
import JobOffer from '../containers/JobOffer/JobOfferReducer';
import { JobOfferTemplateReducer as JobOfferTemplate } from '../containers/JobOfferTemplate';
import { JobTemplatesReducer as JobTemplate } from '../containers/JobTemplates';
import MarketplaceApps from '../containers/MarketplaceApps/Reducer';
import NotificationsV2 from '../containers/Notifications/NotificationsReducerV2';
import PreHireStep from '../containers/PreHireStep/Reducer';
import RootSetup from '../containers/RootSetup/redux/RootSetupReducer';
import SetupEngageSurvey from '../containers/SetupEngageSurvey/SetupEngageSurveyReducer';
import SetupHomePage from '../containers/SetupHomePage/Reducer';
import SetupOrganizationalStructure from '../containers/SetupOrganizationalStructure/Reducer';
import SocialMedias from '../containers/SocialMedias/Reducer';
import Task from '../containers/Task/TaskReducer';
import TestProviders from '../containers/TestSelection/TestProviders/TestProvidersReducer';
import { UserAccessProfileReducer as UserAccessProfile } from '../containers/UserAccessProfile';
import UserProfile from '../containers/UserProfile/Reducer';
import WhatsAppBusinessTemplate from '../containers/WhatsappBusinessTemplate/Reducer';
import WhatsAppSetup from '../containers/WhatsAppSetup/Reducer';
import { CustomTestReducers } from '../external/CustomTest';
import JobShare from '../pages/JobShare/Reducer';
import JobList from '../pages/OldJobList/Reducer';
import ApprovalWorkflow from '../stores/modules/ApprovalWorkflow/ApprovalWorkflowReducer';
import Address from './Address/AddressReducer';
import App from './App/AppReducer';
import Department from './Department/DepartmentReducer';
import Error from './Error/Error';
import Inbound from './Inbound/InboundReducer';
import JobApplication from './Job/JobApplication/JobApplicationReducer';
import JobCandidatesClassifier from './Job/JobCandidatesClassifiers/JobCandidatesClassifiersReducer';
import JobDetail from './Job/JobDetailReducer';
import JobInternalData from './Job/JobInternalDataReducer';
import JobPicture from './Job/JobPicture/JobPictureReducer';
import JobPublicationType from './Job/JobPublicationType/JobPublicationTypeReducer';
import JobRatingCriterias from './Job/JobRatingCriterias/JobRatingCriteriasReducer';
import Job from './Job/JobReducer';
import JobStepCustom from './Job/JobStep/JobStepCustomReducer';
import JobStepFinal from './Job/JobStep/JobStepFinalReducer';
import JobSteps from './Job/JobStep/JobStepsReducer';
import JobWorkplace from './Job/JobWorkplaceReducer';
import Profile from './Profile/ProfileReducer';
import ProfileTest from './ProfileTest/ProfileTestReducer';
import Role from './Role/RoleReducer';
import Subsidiary from './Subsidiary/SubsidiaryReducer';

export default combineReducers({
  ...CustomTestReducers,
  Address,
  CustomAddress,
  ApiAccessPolicy,
  App,
  ApprovalWorkflow,
  Authentication,
  CandidateInsertionReducer,
  CandidateTests,
  MarketplaceApps,
  ConfirmIndication,
  Department,
  EmailTemplate,
  JobOfferTemplate,
  Error,
  ForgotPassword,
  HiringInformationReducer,
  Home,
  Inbound,
  Integrations,
  InterviewEvent,
  InterviewEvents,
  InterviewScriptV2,
  HiringCelebration,
  Job,
  JobList,
  JobApplication,
  JobCandidatesClassifier,
  JobClosureReducer,
  JobDeletionReducer,
  JobDetail,
  JobInternalData,
  JobOverview,
  JobPicture,
  JobPublicationType,
  JobRatingCriterias,
  JobShare,
  JobStepCustom,
  JobStepFinal,
  JobStepRegister,
  JobRegistrationForm,
  JobSteps,
  Analytics,
  JobTemplate,
  JobWorkplace,
  NotificationsV2,
  SetupOrganizationalStructure,
  SetupHomePage,
  Profile,
  ProfileTest,
  Role,
  SetPassword,
  Signin,
  SignOut,
  SocialMedias,
  Subsidiary,
  TestProviders,
  UserAccessProfile,
  UserProfile,
  RootSetup,
  JobCustomFormTemplate,
  Task,
  PreHireStep,
  CustomForms,
  EmailDomains,
  CareerPage,
  JobCancel,
  IndicatorsHomeInsights,
  JobWeekInsights,
  WhatsAppBusinessTemplate,
  WhatsAppSetup,
  WhatsAppSendMessageModalReducer,
  DiversityLegalTerms,
  CompanyEmployee,
  CurriculumDownload,
  JobSummary,
  JobApplicationHistory,
  GupyBadgeFeedbackInsight,
  Timeline,
  RelocateCandidate,
  OnboardingGaia,
  JobOffer,
  SetupEngageSurvey,
  SkillsEvaluationLegacy,
  NewHomeJobWeekInsights,
});
