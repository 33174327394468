import { NewBadge } from '@gupy/front-commons';
import { useFlag } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import GridSidebarMenu from '../../components/Grid/GridSidebarMenu';
import featuresFlagsEnum from '../../containers/Authentication/FeaturesFlagsEnum';
import SectionItemWithoutActiveClass from './components/SectionItemWithoutActiveClass/SectionItemWithoutActiveClass';
import selectors from './selectors';
import getLabels from './SetupGridSidebarMenu.labels';
import SetupSectionDivider from './SetupSectionDivider';
import SetupSectionItem from './SetupSectionItem';
import './SetupGridSidebarMenu.scss';

const propTypes = {
  permissions: PropTypes.object,
  features: PropTypes.object,
  intl: intlShape,
};

const defaultProps = {
  permissions: {},
  features: {},
};

export const setupPermissionsAndLinks = {
  edit_company_profile: '/companies/setup/profile',
  edit_company_career_page: '/companies/setup/career-pages',
  edit_company_social_media: '/companies/setup/social-medias',
  view_role: '/companies/setup/roles',
  view_department: '/companies/setup/departments',
  view_subsidiary: '/companies/setup/subsidiaries',
  view_company_user: '/companies/setup/users',
  view_job_template: '/companies/setup/templates',
  view_job_offer_template: '/companies/setup/job-offer-template',
  view_email_template: '/companies/setup/email-template',
  view_public_email_template: '/companies/setup/email-template',
  view_whatsapp_business_template:
    '/companies/setup/whatsapp-business-template',
  view_company_user_access_profile: '/companies/setup/user-access-profile',
  view_company_integrations: '/companies/setup/integrations/default',
  view_marketplace_apps: '/companies/setup/marketplace-apps',
  view_custom_test: '/companies/setup/tests',
  view_company_test_average: '/companies/setup/profile-test-average',
  view_organizational_structure: '/companies/setup/organizational-structure',
  view_interview_scripts: '/companies/setup/interview-scripts',
  create_public_api_access_token: '/companies/setup/api-access-policies',
  view_email_domains: '/companies/setup/email-domains',
  edit_saml: '/companies/setup/integrations/saml',
  edit_saml_sso: '/companies/setup/integrations/samlSSO',
  edit_interview_event: '/companies/setup/integrations/interviewEvent',
  view_approval_workflows: '/companies/setup/approval-workflows',
  view_workflows: '/companies/setup/workflows',
  create_custom_form_template_setup: '/companies/setup/custom-form-template',
  root_setup: '/companies/setup/root',
  home_setup: '/companies/setup/',
  enable_pre_hire_step: '/companies/setup/pre-hire-step',
  admission_documents: '/companies/setup/admission/documents',
  admission_custom_fields: '/companies/setup/admission/custom-fields',
  admission_templates: '/companies/setup/admission/templates',
  admission_signature_templates:
    '/companies/setup/admission/signature-templates',
  admission_workflow: '/companies/setup/admission/workflow',
  admission_cost_centers: '/companies/setup/admission/cost-centers',
  admission_public_api_tokens: '/companies/setup/admission/public-api',
  admission_work_shifts: '/companies/setup/admission/work-shifts',
  admission_integrations: '/companies/setup/admission/integrations',
  admission_benefits: '/companies/setup/admission/benefits',
  admission_contracts: '/companies/setup/admission/contracts',
  admission_terms: '/companies/setup/admission/terms',
  whatsapp_business_integration:
    '/companies/setup/whatsapp-business-integration',
  edit_company_employees: '/companies/setup/employees',
  view_cultural_fit_mapping: '/companies/setup/cultural-fit-mapping',
  view_engage_survey: '/companies/setup/engage-survey',
  setup_skills: 'companies/setup/skills',

  // Need this to redirect from setup when only admission is allowed
  view_all_admission: '/companies/setup/admission/templates',
  view_branch_admission: '/companies/setup/admission/templates',
  view_company_plan_resources_comsumption:
    '/companies/setup/company-plan-information',
};

const SetupGridSidebarMenu = ({ permissions, features, intl }) => {
  const labels = getLabels(intl);
  const isRepackagingEnabled = useFlag(featuresFlagsEnum.repackaging);
  const isWorkflowEnabled = useFlag(featuresFlagsEnum.workflow);
  const isWorkflowSimplifiedEnabled = useFlag(
    featuresFlagsEnum.workflowSimplified,
  );
  const isWorkflowBlocked = isRepackagingEnabled && !isWorkflowEnabled;

  const isJobOfferEnable = useFlag(featuresFlagsEnum.jobOffer);
  const isCulturalFitMappingEnable = useFlag(
    featuresFlagsEnum.culturalFitMapping,
  );
  const isEngageSurveyEnable = useFlag(featuresFlagsEnum.engageSurvey);
  const isProfileTestDisabled = useFlag(featuresFlagsEnum.disableProfileTest);
  const isSamlEnabled = useFlag(featuresFlagsEnum.isSamlEnabled);
  const isJobCustomFieldsEnabled = useFlag(featuresFlagsEnum.jobCustomFields);
  const isSchedulingIntegrationEnabled = useFlag(
    featuresFlagsEnum.showSchedulingIntegration,
  );

  const isManageAcceptanceTermsEnabled = useFlag(
    featuresFlagsEnum.admissionManageAcceptanceTerms,
  );

  const isCustomTestsEnabled = useFlag(featuresFlagsEnum.customTests);

  const isTestProvidersEnabled = useFlag(featuresFlagsEnum.testProviders);
  const isPublicApiAccessPoliciesEnabled = useFlag(
    featuresFlagsEnum.publicApiAccessPolicies,
  );

  const isApprovalWorkflowsEnabled = useFlag(
    featuresFlagsEnum.approvalWorkflows,
  );
  const isSetupSkillsEnabled = useFlag(featuresFlagsEnum.setupSkills);

  const isBusinessWhatsAppEnabled = useFlag(featuresFlagsEnum.businessWhatsApp);

  const isAllTestsBlocked =
    isRepackagingEnabled && !isCustomTestsEnabled && !isTestProvidersEnabled;

  const isGenAiInterviewScriptEnabled = useFlag(
    featuresFlagsEnum.genAiInterviewScript,
  );
  const isOrganizationalStructureEnabled = useFlag(
    featuresFlagsEnum.companyBranch,
  );
  const isInternalRecruitmentEnabled = useFlag(
    featuresFlagsEnum.internalRecruitment,
  );

  const isJobOfferBlocked = isRepackagingEnabled && !isJobOfferEnable;

  const hasOrganizationalStructureFeature = isOrganizationalStructureEnabled;

  const hasSubsidiaryFeature = !isOrganizationalStructureEnabled;

  const isCompanyPlanInformationEnabled = useFlag(
    featuresFlagsEnum.planInformation,
  );

  const hasManageIntegrationsPermission =
    permissions.view_company_integrations &&
    permissions.edit_company_integrations;

  const hasAdmissionPermission =
    permissions.view_all_admission || permissions.view_branch_admission;

  const hasAdmissionCustomFieldsCreateOrEditPermission =
    permissions.admission_create_custom_fields ||
    permissions.admission_edit_custom_fields;

  const useLegacyProfileTest = features.legacyProfileTest;

  const isPreHireStepEnabled = useFlag(featuresFlagsEnum.preHireStep);

  const isAdmissionOnly = features.admissionOnly;

  const { pathname } = window.location;

  const hasCostCenterPermission =
    permissions.admission_cost_centers_view && features.admission;

  const hasAdmissionTokensPermission =
    permissions.admission_api_token_view && features.admission;

  const hasAdmissionWorkShiftsPermission =
    permissions.admission_view_work_shifts && features.admission;

  const hasAdmissionFieldMappingPermission =
    features.admissionFieldMapping &&
    permissions.admission_map_fields &&
    features.admission;

  const hasAdmissionManageBulkContractPermission =
    permissions.admission_manage_bulk_contract &&
    features.admissionPremiumClient &&
    features.admission &&
    features.admissionContracts;

  const renderAdmissionOnlySetupItems = () => (
    <div>
      <SetupSectionDivider
        id="companys-structure"
        title={labels.companyStructure}
      >
        <SetupSectionItem
          link={setupPermissionsAndLinks.edit_company_profile}
          hasPermission={permissions.edit_company_profile}
        >
          {labels.profile}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_role}
          hasPermission={permissions.view_role}
        >
          {labels.roles}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_department}
          hasPermission={permissions.view_department}
        >
          {labels.departments}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_subsidiary}
          hasPermission={permissions.view_subsidiary && hasSubsidiaryFeature}
        >
          {labels.subsidiaries}
        </SetupSectionItem>
      </SetupSectionDivider>
      <SetupSectionDivider
        id="access-to-the-platform"
        title={labels.accessToThePlatform}
      >
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_company_user_access_profile}
          hasPermission={permissions.view_company_user_access_profile}
        >
          {labels.userProfiles}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_company_user}
          hasPermission={permissions.view_company_user}
        >
          {labels.users}
        </SetupSectionItem>
      </SetupSectionDivider>
      <SetupSectionDivider
        id="advanced-settings"
        title={labels.advancedSettings}
      >
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_benefits}
          hasPermission={features.admission}
        >
          {labels.admissionBenefitsSetting}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_contracts}
          hasPermission={hasAdmissionManageBulkContractPermission}
        >
          {labels.admissionContractsSetting}
          <span className="badge-new">{labels.new}</span>
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_workflow}
          hasPermission={features.admission}
        >
          {labels.admissionWorkflow}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_documents}
          hasPermission={features.admission}
        >
          {labels.admissionDocuments}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_templates}
          hasPermission={features.admission}
        >
          {labels.admissionTemplates}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_terms}
          hasPermission={isManageAcceptanceTermsEnabled}
        >
          {labels.admissionManageAcceptanceTerms}
          <span className="badge-new">{labels.new}</span>
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_custom_fields}
        >
          {labels.admissionCustomFields}
        </SetupSectionItem>
        <SetupSectionItem
          link={
            setupPermissionsAndLinks.view_email_template ||
            setupPermissionsAndLinks.view_public_email_template
          }
          hasPermission={
            permissions.view_email_template ||
            permissions.view_public_email_template
          }
        >
          {labels.emailTemplates}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_integrations}
          hasPermission={features.admissionFieldMapping && features.admission}
        >
          {labels.admissionIntegrations}
        </SetupSectionItem>
      </SetupSectionDivider>
    </div>
  );

  const renderSetupItems = () => (
    <div>
      <div id="company-plan-item">
        <SetupSectionItem
          link={
            setupPermissionsAndLinks.view_company_plan_resources_comsumption
          }
          hasPermission={
            isCompanyPlanInformationEnabled &&
            permissions.view_company_plan_resources_comsumption
          }
        >
          {labels.myCompanyPlan}
        </SetupSectionItem>
      </div>
      <SetupSectionDivider
        id="companys-structure"
        title={labels.companyStructure}
      >
        <SetupSectionItem
          link={setupPermissionsAndLinks.edit_company_profile}
          hasPermission={permissions.edit_company_profile}
        >
          {labels.profile}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_role}
          hasPermission={permissions.view_role}
        >
          {labels.roles}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_department}
          hasPermission={permissions.view_department}
        >
          {labels.departments}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_subsidiary}
          hasPermission={permissions.view_subsidiary && hasSubsidiaryFeature}
        >
          {labels.subsidiaries}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_organizational_structure}
          hasPermission={
            permissions.view_organizational_structure &&
            hasOrganizationalStructureFeature
          }
          shouldBlockFeature={
            isRepackagingEnabled && !hasOrganizationalStructureFeature
          }
        >
          {labels.organizationalStructure}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.edit_company_employees}
          hasPermission={
            (permissions.edit_company_employees ||
              permissions.view_company_employees) &&
            isInternalRecruitmentEnabled
          }
        >
          {labels.employees}
        </SetupSectionItem>
      </SetupSectionDivider>
      <SetupSectionDivider id="sharing" title={labels.sharing}>
        <SetupSectionItem
          link={setupPermissionsAndLinks.edit_company_career_page}
          hasPermission={permissions.edit_company_career_page}
        >
          {labels.careerPages}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.edit_company_social_media}
          hasPermission={permissions.edit_company_social_media}
        >
          {labels.socialMedias}
        </SetupSectionItem>
      </SetupSectionDivider>
      <SetupSectionDivider
        id="access-to-the-platform"
        title={labels.accessToThePlatform}
      >
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_company_user_access_profile}
          hasPermission={permissions.view_company_user_access_profile}
        >
          {labels.userProfiles}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_company_user}
          hasPermission={permissions.view_company_user}
        >
          {labels.users}
        </SetupSectionItem>
      </SetupSectionDivider>
      <SetupSectionDivider id="tests" title={labels.testSteps}>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_custom_test}
          hasPermission={permissions.view_custom_test}
          shouldBlockFeature={isAllTestsBlocked}
        >
          {labels.partnersCustoms}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_company_test_average}
          hasPermission={!useLegacyProfileTest && !isProfileTestDisabled}
        >
          {labels.behavioralResult}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_cultural_fit_mapping}
          hasPermission={isCulturalFitMappingEnable}
        >
          {labels.culturalFitMapping}
        </SetupSectionItem>
      </SetupSectionDivider>
      <SetupSectionDivider id="templates" title={labels.templates}>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_job_template}
          hasPermission={permissions.view_job_template}
        >
          {labels.jobTemplates}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_approval_workflows}
          hasPermission={
            isApprovalWorkflowsEnabled &&
            permissions.edit_approval_workflows &&
            !isWorkflowEnabled &&
            !isWorkflowSimplifiedEnabled
          }
        >
          {labels.approvalWorkflow}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_workflows}
          hasPermission={
            isWorkflowEnabled && permissions.edit_approval_workflows
          }
          shouldBlockFeature={isWorkflowBlocked}
        >
          {labels.workflows}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_job_offer_template}
          hasPermission={
            permissions.view_job_offer_template && isJobOfferEnable
          }
          shouldBlockFeature={isJobOfferBlocked}
        >
          {labels.jobOfferTemplates}
        </SetupSectionItem>
        <SetupSectionItem
          link={
            setupPermissionsAndLinks.view_email_template ||
            setupPermissionsAndLinks.view_public_email_template
          }
          hasPermission={
            permissions.view_email_template ||
            permissions.view_public_email_template
          }
        >
          {labels.emailTemplates}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_whatsapp_business_template}
          hasPermission={
            isBusinessWhatsAppEnabled &&
            permissions.view_whatsapp_business_template
          }
        >
          {labels.whatsAppBusinessPage}
        </SetupSectionItem>

        {!isGenAiInterviewScriptEnabled && (
          <SetupSectionItem
            link={setupPermissionsAndLinks.view_interview_scripts}
            hasPermission={permissions.view_interview_scripts}
          >
            {labels.interviewScripts}
          </SetupSectionItem>
        )}

        <SetupSectionItem
          link={setupPermissionsAndLinks.create_custom_form_template_setup}
          hasPermission={permissions.create_custom_form_template_setup}
          shouldBlockFeature={isRepackagingEnabled && !isJobCustomFieldsEnabled}
        >
          {labels.customFields}
        </SetupSectionItem>
      </SetupSectionDivider>
      <SetupSectionDivider
        id="advanced-settings"
        title={labels.advancedSettings}
      >
        <SetupSectionItem
          link={setupPermissionsAndLinks.create_public_api_access_token}
          hasPermission={
            permissions.create_public_api_access_token &&
            isPublicApiAccessPoliciesEnabled
          }
          shouldBlockFeature={
            isRepackagingEnabled && !isPublicApiAccessPoliciesEnabled
          }
        >
          {labels.apiAccessToken}
        </SetupSectionItem>
        {isSetupSkillsEnabled && permissions.manage_setup_skills && (
          <SetupSectionItem
            link={setupPermissionsAndLinks.setup_skills}
            hasPermission={permissions.manage_setup_skills}
          >
            {labels.skills}
          </SetupSectionItem>
        )}
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_email_domains}
          hasPermission={permissions.manage_recommendation_email_domain}
        >
          {labels.emailDomainIndication}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.whatsapp_business_integration}
          hasPermission={isBusinessWhatsAppEnabled}
        >
          {labels.whatsAppBusinessIntegration}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_company_integrations}
          hasPermission={permissions.view_company_integrations}
        >
          {labels.integrations}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_engage_survey}
          hasPermission={isEngageSurveyEnable}
        >
          {labels.engageSurvey}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.view_marketplace_apps}
          hasPermission
        >
          {labels.marketplaceApps}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.edit_interview_event}
          hasPermission={
            permissions.view_company_integrations &&
            isSchedulingIntegrationEnabled
          }
          shouldBlockFeature={
            isRepackagingEnabled && !isSchedulingIntegrationEnabled
          }
        >
          {labels.interviewEvent}
          <NewBadge releasedAt="2019-09-01" />
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.edit_saml}
          hasPermission={permissions.edit_saml && features.samlLogin}
        >
          {labels.saml}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.edit_saml_sso}
          hasPermission={permissions.edit_saml && isSamlEnabled}
        >
          {labels.samlSSO}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.enable_pre_hire_step}
          hasPermission={
            hasManageIntegrationsPermission && isPreHireStepEnabled
          }
        >
          {labels.preHireStep}
        </SetupSectionItem>
      </SetupSectionDivider>
      <SetupSectionDivider
        id="admission-settings"
        title={labels.admissionSettings}
      >
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_benefits}
          hasPermission={hasAdmissionPermission && features.admission}
        >
          {labels.admissionBenefitsSetting}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_contracts}
          hasPermission={
            hasAdmissionPermission && hasAdmissionManageBulkContractPermission
          }
        >
          {labels.admissionContractsSetting}
          <span className="badge-new">{labels.new}</span>
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_workflow}
          hasPermission={hasAdmissionPermission && features.admission}
        >
          {labels.admissionWorkflow}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_documents}
          hasPermission={hasAdmissionPermission && features.admission}
        >
          {labels.admissionDocuments}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_templates}
          hasPermission={hasAdmissionPermission && features.admission}
        >
          {labels.admissionTemplates}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_terms}
          hasPermission={isManageAcceptanceTermsEnabled}
        >
          {labels.admissionManageAcceptanceTerms}
          <span className="badge-new">{labels.new}</span>
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_custom_fields}
          hasPermission={
            hasAdmissionCustomFieldsCreateOrEditPermission && features.admission
          }
        >
          {labels.admissionCustomFields}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_signature_templates}
          hasPermission={hasAdmissionPermission && features.admission}
        >
          {labels.admissionSignatureTemplates}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_cost_centers}
          hasPermission={hasCostCenterPermission}
        >
          {labels.costCenters}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_public_api_tokens}
          hasPermission={hasAdmissionTokensPermission}
        >
          {labels.apiAccessToken}
          <span className="badge-new">{labels.new}</span>
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_work_shifts}
          hasPermission={hasAdmissionWorkShiftsPermission}
        >
          {labels.workShifts}
        </SetupSectionItem>
        <SetupSectionItem
          link={setupPermissionsAndLinks.admission_integrations}
          hasPermission={hasAdmissionFieldMappingPermission}
        >
          {labels.admissionIntegrations}
          <span className="badge-new">{labels.new}</span>
        </SetupSectionItem>
      </SetupSectionDivider>
    </div>
  );

  return (
    <GridSidebarMenu title={labels.setup} description={labels.setupDescription}>
      {['/companies/setup/', '/companies/setup'].includes(pathname) ? (
        <SetupSectionItem
          link={setupPermissionsAndLinks.home_setup}
          hasPermission
        >
          {labels.setupInitialPage}
        </SetupSectionItem>
      ) : (
        <SectionItemWithoutActiveClass
          link={setupPermissionsAndLinks.home_setup}
          hasPermission
        >
          {labels.setupInitialPage}
        </SectionItemWithoutActiveClass>
      )}
      {isAdmissionOnly ? renderAdmissionOnlySetupItems() : renderSetupItems()}
    </GridSidebarMenu>
  );
};

SetupGridSidebarMenu.propTypes = propTypes;
SetupGridSidebarMenu.defaultProps = defaultProps;

export default injectIntl(connect(selectors)(SetupGridSidebarMenu));
